<script>
import between from 'vuelidate/lib/validators/between';
import { alphaNumDash, inArray } from '@shared/config/vuelidate';
import { genericForms } from '@shared/mixins/forms';

const DISCOUNT_TYPES = {
  AMOUNT: 'Fixe',
  PERCENTAGE: 'Pourcentage',
};

export default {
  mixins: [genericForms],
  form: {
    name: {
      label: 'Description *',
      type: 'text',
      column: 'is-6',
      fieldAttrs: {
        message: 'Décrivez votre code promo afin de pouvoir l\'identifier',
      },
      inputAttrs: {
        maxlength: 64,
        hasCounter: false,
        required: true,
      },
    },
    code: {
      label: 'Code *',
      type: 'text',
      column: 'is-6',
      errors: ['alphaNumDash'],
      inputAttrs: {
        maxlength: 32,
        hasCounter: false,
        required: true,
      },
    },
    show_trainings: {
      switchLabel: 'Restreindre à certains produits',
      type: 'switch',
      column: 'is-12',
      options: [],
    },
    training_uuids: {
      selectLabel: 'Choisissez une formation...',
      type: 'multiselect',
      column: 'is-12',
      inputAttrs: {
        required: false,
        floating: true,
      },
    },
    bundle_uuids: {
      selectLabel: 'Choisissez un pack...',
      type: 'multiselect',
      column: 'is-12',
      inputAttrs: {
        required: false,
        floating: true,
      },
    },
    type: {
      label: 'Type *',
      type: 'select',
      column: 'is-6',
      errors: ['inArray'],
      options: DISCOUNT_TYPES,
      inputAttrs: {
        required: true,
      },
    },
    value: {
      label: 'Montant de la réduction *',
      type: 'number',
      column: 'is-6',
      errors: [{ name: 'between', args: [0, 100] }],
      inputAttrs: {
        min: 0,
        step: 'any',
        required: true,
      },
    },
    max_usage: {
      label: 'Nombre d\'utilisation maximum',
      type: 'number',
      column: 'is-12',
      inputAttrs: {
        min: 1,
        max: 100000,
      },
    },
    start: {
      label: 'Début',
      type: 'datetimepicker',
      column: 'is-6',
      fieldAttrs: {
        message: 'Date à partir de laquelle ce code promo est utilisable.',
      },
      inputAttrs: {
        horizontalTimePicker: true,
        position: 'is-bottom-left',
        datepicker: {
          yearsRange: [-1, 3],
        },
      },
    },
    end: {
      label: 'Expiration',
      type: 'datetimepicker',
      column: 'is-6',
      fieldAttrs: {
        message: 'Date à partir de laquelle ce code promo expire.',
      },
      inputAttrs: {
        horizontalTimePicker: true,
        position: 'is-bottom-left',
        datepicker: {
          yearsRange: [-1, 3],
        },
      },
    },
  },
  data() {
    return {
      discount: {
        name: '',
        code: '',
        show_trainings: false,
        training_uuids: [],
        bundle_uuids: [],
        type: 'AMOUNT',
        value: '',
        max_usage: '',
        start: null,
        end: null,
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    trainings() {
      const { list } = this.$store.state.trainings;
      return list ? list.data : null;
    },
    bundles() {
      const { list } = this.$store.state.bundles;
      return list ? list.data : null;
    },
  },
  validations() {
    const discount = {
      code: {
        alphaNumDash,
      },
      type: {
        inArray: inArray(Object.keys(DISCOUNT_TYPES)),
      },
    };

    if (this.discount.type === 'PERCENTAGE') {
      discount.value = {
        between: between(0, 100),
      };
    }

    return { discount };
  },
  methods: {
    action() {
      const storeUUID = this.authStore.uuid;
      const discount = { ...this.discount };

      if (discount.start) {
        discount.start = this.$moment(discount.start).utc().format('YYYY-MM-DD HH:mm:ss');
      }

      if (discount.end) {
        discount.end = this.$moment(discount.end).utc().format('YYYY-MM-DD HH:mm:ss');
      }

      if (!discount.show_trainings) {
        delete discount.training_uuids;
        delete discount.bundle_uuids;
      }

      delete discount.show_trainings;

      this.isLoading = true;
      return this.$store.dispatch('discounts/add', { storeUUID, discount })
        .finally(() => {
          Object.assign(this.$data, this.$options.data.apply(this));
          this.$v.$reset();
        });
    },
  },
  mounted() {
    const loader = this.$buefy.loading.open({ container: this.$refs.form });
    this.$store.dispatch('trainings/fetch')
      .then(() => {
        this.$options.form.training_uuids.options = this.trainings.reduce((prev, curr) => {
          prev[curr.uuid] = curr.name;
          return prev;
        }, {});
      })
      .finally(() => loader.close());
    this.$store.dispatch('bundles/fetch')
      .then(() => {
        this.$options.form.bundle_uuids.options = this.bundles.reduce((prev, curr) => {
          prev[curr.uuid] = curr.name;
          return prev;
        }, {});
      })
      .finally(() => loader.close());
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Créez des codes promo
      </h2>
      <p>
        La <strong>description</strong> sert à vous y retrouver et le <strong>code</strong> correspond à l'identifiant
        unique que les acheteurs entrerons lors de leur achat.
      </p>
      <p>
        Par défaut, les codes promo créés sont <strong>applicables sur TOUTES</strong> vos formations.
        Néanmoins, vous pouvez choisir de n'appliquer
        certains codes promo qu'à certaines formations.
      </p>
      <p>
        Vous pouvez définir un nombre maximum d'utilisation de votre code promo,
        ou laisser le champ vide pour qu'il s'applique sans restriction.
      </p>
      <p>
        Si vous possédez le Pack PRO, vous pouvez créer autant de codes promo
        que vous le voulez. Avec le Pack DÉCOUVERTE,
        vous n'avez la possibilité de créer que 3 codes promo maximum.
      </p>
    </div>

    <div class="column">
      <form ref="form" class="box is-relative" @submit.prevent="dataIsValid(isLoading) && handle()">
        <div class="columns is-multiline">
          <template v-for="(field, k) in $options.form">
            <div
              v-if="(k != 'training_uuids' || (k == 'training_uuids' && discount.show_trainings))
              && (k != 'bundle_uuids' || (k == 'bundle_uuids' && discount.show_trainings))"
              :key="k"
              class="column"
              :class="field.column">
              <BaseField v-model="discount[k]" :v="$v.discount[k]" :field="field" />
            </div>
          </template>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Créer
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>
